import axios from 'axios';
import { FORM_FIELDS } from '../../constants';
import { EMPLOYMENT, GENDER } from '../../services/offers/offers.constants';
import {
    BriefOptions,
    BriefResponse,
    FetchOptions,
    FetchResponseV2,
    StatusInfo,
} from '../../services/offers/offers.types';
import { UtilizationRequestParams, UtilizationResponse } from '../../services/offers/utilization/types';
import { dateDiffMonths, dateDiffYears, dateStringToTimestamp, sourceNameByValue } from '../index';
import { ILocation } from '../../store/reducers/locations';
import { ADDITIONAL_PHONE_OWNER_SOURCE, CAR_VALUES, EMPLOYMENT_VALUES } from '../../constants/sources';

interface IGetParamsOptions {
    useGen7: boolean;
}

export class OffersProvider {
    constructor(protected path: string) {}

    getParams = (formData: FE.FormData, region: ILocation, userId?: string): FetchOptions => {
        const gender = formData[FORM_FIELDS.GENDER];
        const partnerId = formData[FORM_FIELDS.PARTNER_ID] || undefined;

        return {
            gender: Object.values(GENDER).includes(gender) ? gender : GENDER.UNKNOWN,
            amount: Number(formData[FORM_FIELDS.AMOUNT]),
            locationRoute: region.name === formData[FORM_FIELDS.REGION] ? region.route : '6.',
            registrationLocationRoute: formData[FORM_FIELDS.REGISTRATION_ROUTE],
            residentialLocationRoute: formData[FORM_FIELDS.RESIDENCE_ROUTE],
            workLocationRoute: formData[FORM_FIELDS.WORK_ROUTE],
            term: formData[FORM_FIELDS.PERIOD],
            age: dateDiffYears(new Date(), new Date(dateStringToTimestamp(formData[FORM_FIELDS.BIRTHDAY]))),
            periodOfResidence: formData[FORM_FIELDS.REGISTRATION_DATE],
            monthlyIncome: Number(formData[FORM_FIELDS.MONTHLY_SALARY]),
            employment: formData[FORM_FIELDS.EMPLOYMENT_TYPE] || EMPLOYMENT.NOT_WORKING_VALUE,
            jobType: formData[FORM_FIELDS.JOB_TYPE],
            lastPlaceworkExperience: this.getLastExperience(formData),
            solvencyProof: formData[FORM_FIELDS.INCOME_CONFIRMATION],
            education: formData[FORM_FIELDS.EDUCATION],
            inn: formData[FORM_FIELDS.EMPLOYER_INN],
            flatNumber: Number(formData[FORM_FIELDS.REGISTRATION_APARTMENT]) || undefined,
            areaOfEmployment: formData[FORM_FIELDS.AREA_OF_EMPLOYMENT],
            email: formData[FORM_FIELDS.EMAIL],
            creditSecurity: formData[FORM_FIELDS.CREDIT_SECURITY],
            opf: formData[FORM_FIELDS.EMPLOYER_TYPE],
            customerProperty: formData[FORM_FIELDS.PROPERTY_TYPE],
            maritalStatus: formData[FORM_FIELDS.FAMILY_STATUS],
            dependents: formData[FORM_FIELDS.NUMBER_OF_DEPENDENTS] || undefined,
            carManufacturer: formData[FORM_FIELDS.CAR] || CAR_VALUES.NO,
            additionalPhoneOwner: sourceNameByValue(
                formData[FORM_FIELDS.ADDITIONAL_PHONE_OWNER],
                ADDITIONAL_PHONE_OWNER_SOURCE,
            ) as string,
            snils: formData[FORM_FIELDS.SNILS],
            gen7: formData[FORM_FIELDS.SCORE_GEN7_CL] || undefined,
            nbki: formData[FORM_FIELDS.NBKI_SCORE] || undefined,
            phone: formData[FORM_FIELDS.PHONE].replace(/\D/g, ''),
            sourceUrl: typeof window !== 'undefined' ? window.location.href : undefined,
            filteredOrganizationId: formData[FORM_FIELDS.FILTERED_ORGANIZATION_ID] as string,
            purpose: formData[FORM_FIELDS.PURPOSE],
            initialAmount: Number(formData[FORM_FIELDS.INITIAL_FEE]),
            userId,
            autoBrandId: formData[FORM_FIELDS.AUTO_BRAND_ID] || undefined,
            autoModelId: formData[FORM_FIELDS.AUTO_MODEL_ID] || undefined,
            partnerId,
            passportSeriesNumber: formData[FORM_FIELDS.PASSPORT_NUMBER],
            passportIssuerCode: formData[FORM_FIELDS.UNIT_CODE],
            passportIssuedBy: formData[FORM_FIELDS.ISSUED],
            passportIssueDate: formData[FORM_FIELDS.PASSPORT_DATE],
            seniority: formData[FORM_FIELDS.SENIORITY] || undefined,
            okved: formData[FORM_FIELDS.OKVED] || undefined,
            smsCodeDate: formData[FORM_FIELDS.SMS_CODE_DATE],
        };
    };

    private isUnemployed = (formData: FE.FormData) => {
        return [EMPLOYMENT_VALUES.NOT_WORKING_VALUE, EMPLOYMENT_VALUES.RETIRED].includes(
            formData[FORM_FIELDS.EMPLOYMENT_TYPE],
        );
    };

    private getLastExperience = (formData: FE.FormData) => {
        if (this.isUnemployed(formData)) {
            return 0;
        }
        const month = parseInt(formData[FORM_FIELDS.LAST_EXPERIENCE_START_MONTH], 10) - 1;
        const year = parseInt(formData[FORM_FIELDS.LAST_EXPERIENCE_START_YEAR], 10);
        return dateDiffMonths(new Date(year, month, 1), new Date());
    };

    load = async (
        params: FetchOptions,
    ): Promise<
        FetchResponseV2 & {
            isSuccess?: boolean;
        }
    > => {
        try {
            const { data: products } = await axios.post<FetchResponseV2>(`${this.path}/offers/products/`, params, {
                withCredentials: true,
            });
            const { user, ...rest } = products;

            // Приведение типов ID перенесено на ranking-service

            return {
                user: user || [],
                isSuccess: true,
                ...rest,
            };
        } catch (e) {
            return {
                suitable: [],
                recommended: [],
                unsuitable: [],
                user: [],
                isSuccess: false,
            };
        }
    };

    /** Возвращает предложения для лендинга */
    loadBrief = async (params: BriefOptions): Promise<BriefResponse> => {
        try {
            const { data } = await axios.post<BriefResponse>(`${this.path}/offers/credits/brief/`, params, {
                withCredentials: true,
            });
            return data;
        } catch (e) {
            console.log(e);
            return [];
        }
    };

    getUtilizationBanks = async (params: UtilizationRequestParams) => {
        const { data } = await axios.post<UtilizationResponse>(`${this.path}/offers/utilization/`, params);
        return data;
    };

    statuses = async () => {
        const { data } = await axios.get<StatusInfo>(`${this.path}/offers/statuses/`);
        return data;
    };
}
