import { v4 as uuidv4 } from 'uuid';

export const getOrCreateDeviceId = (): string => {
    const deviceIdKey = 'deviceId';
    let deviceId = localStorage.getItem(deviceIdKey);

    if (!deviceId) {
        deviceId = uuidv4();
        localStorage.setItem(deviceIdKey, deviceId);
    }

    return deviceId;
};
