import axios from 'axios';
import { sendSentryClientError } from '../logger';
import { ICreditHistory } from '../../services/creditHistory/types';

export class CreditHistoryProvider {
    constructor(protected path: string) {}

    loadCreditHistory = async (userId): Promise<ICreditHistory | null> => {
        try {
            const { data } = await axios.get<ICreditHistory>(`${this.path}/credit-history/last`, {
                params: userId ? { userId } : {},
            });
            return data;
        } catch (e) {
            sendSentryClientError(e as Error);
            throw e;
        }
    };
}
