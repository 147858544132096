var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"wsi3vc"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/creditselection-frontend";

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const { sentryDsn, environment: env } = getConfig().publicRuntimeConfig;
/** Так как на dev стенде NODE_ENV=production, ориентируемся на ENV */
const environment = env === 'production' ? 'production' : 'development';

Sentry.init({
    dsn: sentryDsn,
    // отключил сбор web vitals
    tracesSampleRate: 0,
    sampleRate: 0.1,
    logLevel: 1,
    environment,
    enabled: environment === 'production',
    ignoreErrors: [
        /.*failed to fetch.*/i,
        `Failed to execute 'evaluate' on 'Document': The string '' is not a valid XPath expression.`,
        'timeout of 15000ms exceeded',
        'Network Error',
        'Request aborted',
        'Request failed with status code',
        `Failed to execute 'sendBeacon' on 'Navigator': sendBeacon() with a Blob whose type is not any of the CORS-safelisted values for the Content-Type request header is disabled temporarily. See http://crbug.com/490015 for details.`,
        `Failed to fetch`,
        '_targetadsTag is not defined',
        `Can't find variable: _targetadsTag`,
        'Object captured as promise rejection with keys',
        'AbortError: The operation was aborted',
        'OperationError: Internal error retrieving trust token response.',
        'AxiosError: Network Error',
        "undefined is not an object (evaluating 'window.webkit.messageHandlers')",
        'QuotaExceededError: Encountered full disk while opening backing store for indexedDB.open',
        'UnknownError: The user denied permission to access the database.',
        "InvalidStateError: Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.",
        "null is not an object (evaluating 'u.__ybHasBeenPasswordField')",
        "Cannot read properties of undefined (reading 'S')", //recaptcha
        "Cannot read properties of null (reading '2')", // gtm
    ],
    denyUrls: [
        /cdn.uxfeedback.ru\//i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
    ],
});
